<!--
 * @Author: 月魂
 * @Date: 2022-09-19 15:36:12
 * @LastEditTime: 2022-09-20 17:09:30
 * @LastEditors: 月魂
 * @Description: 直播列表
 * @FilePath: \silk-road\src\views\perform\live-list.vue
-->
<template>
  <div class="box">
    <div class="header">
      <img src="http://image.wearetogether.com.cn/8th%2Fassets%2Flist%2Fheader.png" alt="" class="header-img">
      <div class="header-text">
        <van-icon name="arrow-left" class="back" @click="handleBack" />
        <div class="title">直播列表</div>
        <div class="enTitle" v-if="this.$t('perform.enTitle')">live list</div>
      </div>
    </div>
    <!-- 演出列表 -->
    <div class="list">
      <div class="item" @click="jump(perform.id)" v-for="perform in getList" :key="perform.id">
        <img class="top-img" :src="perform.imgUrl" alt="" width="100%">
        <div class="bottom">
          <div class="title">{{ perform.title }}</div>
          <div class="team">{{ perform.team }}</div>
          <div class="time">{{ perform.playTime }}</div>
          <div class="address">{{ statusMap[perform.status] }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Icon } from 'vant'
import dayjs from 'dayjs'
import { handleBack } from '../../utils/util'
Vue.use(Icon)

export default {
  name: 'LiveList',
  components: {
  },
  data() {
    return {
      statusMap: {
        beforeLive: '直播未开始',
        readyLive: '直播中',
        afterLive: '直播已结束'
      }
    }
  },
  computed: {
    getList: function() {
      let list = [];
      let playing = []
      let endLive = []
      list = Object.values(this.$i18n.t('perform.list')).filter(item => item.isLive)
      list.forEach(item => {
        let time = ''
        let status = ''
        if(item.showTime) {
          const start = Object.values(item.liveTime)[0]
          const showTime = Object.values(item.showTime)
          showTime.forEach(item => {
            time += (item + ' ' + start)
          })

          // 生成状态
          const now = dayjs().format('YYYY-MM-DD');
          const liveTime = Object.values(item.liveTime)
          const startTime = now + ' ' + liveTime[0]
          const endTime = now + ' ' + liveTime[1]
          if (showTime.length > 1) {
            if(dayjs().isBefore(showTime[0])) {
              status = 'beforeLive'
              playing.push(item)
            } else if (dayjs().isAfter(showTime[showTime.length - 1] + ' ' + liveTime[1])) {
              status = 'afterLive'
              endLive.push(item)
            } else {
              if(dayjs().isAfter(startTime) && dayjs().isBefore(endTime)) {
                status = 'readyLive'
                playing.push(item)
              } else {
                status = 'beforeLive'
                playing.push(item)
              }
            }
          } else if(showTime.length === 1) {
            if(dayjs().isBefore(showTime[0])) {
              status = 'beforeLive'
              playing.push(item)
            } else if(dayjs().isAfter(showTime[0] + ' ' + liveTime[1])) {
              status = 'afterLive'
              endLive.push(item)
            } else {
              if(dayjs().isAfter(startTime) && dayjs().isBefore(endTime)) {
                status = 'readyLive'
                playing.push(item)
              } else {
                status = 'beforeLive'
                playing.push(item)
              }
            }
          }
        }
        item.status = status
        item.playTime = time
      })
      return [...playing, ...endLive]
    }
  },
  mounted() {
    this.wxShareDef();
  },
  methods: {
    handleBack() {
      handleBack()
    },
    jump(id) { // 此处需传入剧目id
      this.$router.push(`/live/${id}`)
    }
  }
}
</script>

<style lang="less" scoped>
.box {
  min-height: 100vh;
  background: #fcf8ed;
  .header {
    max-width: 1200PX;
    margin: auto;
    color: #fff;
    word-break: break-word;
    position: relative;
    .header-img {
      display: block;
      width: 100%;
    }
    .header-text {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      .back {
        position: absolute;
        top: 8px;
        left: 16px;
        width: 16px;
        font-size: 16px;
      }
      .title {
        font-family: SourceHanSerifCN-Bold;
        font-size: 34px;
        color: #FFF6E2;
        position: absolute;
        top: 24px;
        left: 0;
        right: 0;
        margin: auto;
        text-align: center;
      }
      .enTitle {
        font-family: Arial;
        text-align: center;
        font-size: 12px;
        color: #FFF6E3;
        position: absolute;
        top: 70px;
        left: 0;
        right: 0;
        margin: auto;
      }
    }
  }
  // 演出列表
  .list {
    max-width: 1200PX;
    margin: auto;
    display: flex;
    padding: 16px;
    flex-wrap: wrap;
    justify-content: space-between;
    .item {
      width: 100%;
      background-color: #fff;
      margin-top: 16px;
      border-radius: 10px;
      overflow: hidden;
      &:first-child {
        margin-top: 0;
      }
      .top-img {
        display: block;
        width: 100%;
        border-radius: 10px;
        overflow: hidden;
      }
      .bottom {
        padding: 14px 16px;
        display: flex;
        flex-wrap: wrap;
        font-family: SourceHanSansCN-Medium;
        .title {
          width: 100%;
          font-size: 18px;
          color: #4D4D4D;
        }
        .team {
          width: 100%;
          font-size: 12px;
          color: #999999;
          margin-top: 8px;
        }
        .time {
          font-size: 12px;
          margin-right: 8px;
          color: #999999;
          margin-top: 8px;
        }
        .address {
          font-size: 12px;
          color: #999999;
          margin-top: 8px;
        }
      }
    }
    @media (min-width: 1200PX) {
      padding: 0;
      margin-top: 10px;
      .item {
        width: 49%;
      }
    }
  }
}
</style>

